import React from "react";
import {
  BiPlus,
  BiSearch,
} from "react-icons/bi";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import SelectOption from "../../../components/SelectOption";
import Table from "../../../components/Table";
import TableAction from "../../../components/TableAction";
import { GET_ALL_INDICATOR, typeList } from "../../../constans/constans";
import { EDIT_ACTION_TABLE, INDICATOR_ACTION_TABLE } from "../../../constants";
import { getAllIndicator } from "../../../services/MasterData/indicator";
import Pagination from "../../../components/Pagination";
import { useUtilContexts } from "../../../context/Utils";

const initialFilter = {
  page: 1,
  limit: 20,
  q: "",
  jenis_indikator: "",
};

const Indicator = () => {
  const [filterParams, setFilterParams] = React.useState(initialFilter);
  const { setLoadingUtil, snackbar } = useUtilContexts();
  const navigate = useNavigate();

  const { data, isFetching } = useQuery(
    [GET_ALL_INDICATOR, filterParams],
    getAllIndicator(filterParams),
    {
      retry : 0,
      onError: (error) => {
        snackbar(error?.message || "Terjadi Kesalahan", () => {}, {type : "error"})
      },
    }
  );

  const actionTableData = [
    {
      code: INDICATOR_ACTION_TABLE,
      label : "Skala Indicator",
      onClick: (value) => {
        alert("INDICATOR");
      },
    },
    {
      code: EDIT_ACTION_TABLE,
      label : "Edit",
      onClick: (value) => {
        navigate(`/master/indikator/edit/${value.id}`)
      },
    },
  ];

  const tableHeader = [
    {
      key: "no_urut",
      title: "No. Urut",
      width: 93,
    },
    {
      key: "jenis_indikator",
      title: "Tipe",
      width: 300,
      render : (item) => {
        return typeList.find((type) => type?.value === item?.jenis_indikator)?.label || "-"
      }
    },
    {
      key: "nama_indikator",
      title: "Indikator",
      width: 200,
    },
    {
      key: "nama_dokumen_pendukung",
      title: "Nama Dokumen Pendukung",
      width: 450,
    },
    {
      key: "jenis_file",
      title: "Jenis File",
      width: 120,
    },
    {
      key: "bobot",
      title: "Bobot",
      width: 120,
    },
    {
      key: "parent",
      title: "Parent",
      width: 300,
    },
    {
      key: "mandatory",
      title: "Mandatory",
      width: 120,
    },
    {
      key: "form-action",
      title: "Aksi",
      render: (item) => <TableAction data={actionTableData} itemData={item} />,
    },
  ];

  const onHandlePagination = (page) => {
    setFilterParams({
      ...filterParams,
      page: page + 1,
    });
  };

  const onHandleSearch = (e) => {
    let value = e.target.value;

    if (value.length === 0) {
      setFilterParams({
        ...filterParams,
        q: "",
        page: 1,
      });
    }

    if (value.length >= 3) {
      setFilterParams({
        ...filterParams,
        q: e.target.value,
        page: 1,
      });
    }
  };

  const onChangeIndicatorType = (value) => {
    setFilterParams({
      ...filterParams,
      jenis_indikator : value?.value
    })
  }

  React.useEffect(() => {
    setLoadingUtil(isFetching)
  }, [isFetching])
  
  return (
    <div className="w-full flex flex-col gap-6 py-6">
      <div className="text-[#333333] font-medium text-2xl">Indikator</div>
      <div className="flex justify-end items-center gap-2">
        <Link
          to="/master/indikator/tambah"
          className="text-sm text-white flex items-center gap-2 rounded-lg bg-[#069DD9] cursor-pointer hover:bg-[#1d8bb7] p-[10px] mt-5"
        >
          <BiPlus className="text-base" />
          Tambah Indikator
        </Link>
      </div>
      <div className="w-full rounded-lg bg-white py-4 px-6 flex items-end justify-between">
        <div className="w-[30%]">
          <SelectOption
            label="Jenis Indikator"
            placholder="Pilih indikator"
            options={typeList}
            onChange={onChangeIndicatorType}
          />
        </div>
        <div className="flex items-center gap-3 text-sm border border-[#333333] placeholder:text-[#828282] rounded px-3 py-2 w-[30%]">
          <BiSearch />
          <input
            type="text"
            className="outline-none"
            placeholder="Pencarian"
            onChange={onHandleSearch}
          />
        </div>
      </div>
      <div className="w-full rounded-lg bg-white py-4 px-6">
        <div className="overflow-x-scroll">
          <Table showNum={true} data={data?.data} columns={tableHeader} width="w-[1500px]" />
        </div>
        <Pagination
          pageCount={data?.pagination?.pages}
          onHandlePagination={onHandlePagination}
          totalData={data?.pagination?.total}
          size={filterParams?.limit}
        />
      </div>
    </div>
  );
};

export default Indicator;
