import Button from "../components/Button";

export const jsonHeaderInnovationRegionReview = [
  {
    key: "nomor",
    title: "Nomor",
  },
  {
    key: "judul",
    title: "Judul",
  },
  {
    key: "pemda.nama_pemda",
    title: "Pemda",
  },
  {
    key: "waktu_penerapan",
    title: "Waktu Penerapan",
  },
  {
    key: "skor",
    title: "Skor",
  },
  {
    key: "skor_verifikasi",
    title: "Skor Verifikasi",
  },
  {
    key: "qc",
    title: "QC",
  },
  {
    key: "qc_result",
    title: "Hasil QC",
    render: () => {
      return (
        <Button
          text="Preview"
          fontSize="text-xs"
          background="bg-white"
          fontColor="text-black"
          border="border border-black"
          padding="px-4 p-[8px]"
        />
      );
    },
  },
  {
    key: "action",
    title: "Aksi",
  },
];

export const jsonRowInnovationRegionReview = [
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    score: 90.0,
    verification_score: 90.0,
    quality_check: "BELUM QC",
    qc_result: "-",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    score: 90.0,
    verification_score: 90.0,
    quality_check: "BELUM QC",
    qc_result: "-",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    score: 90.0,
    verification_score: 90.0,
    quality_check: "BELUM QC",
    qc_result: "-",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    score: 90.0,
    verification_score: 90.0,
    quality_check: "BELUM QC",
    qc_result: "-",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    score: 90.0,
    verification_score: 90.0,
    quality_check: "BELUM QC",
    qc_result: "-",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    score: 90.0,
    verification_score: 90.0,
    quality_check: "BELUM QC",
    qc_result: "-",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    score: 90.0,
    verification_score: 90.0,
    quality_check: "BELUM QC",
    qc_result: "-",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    score: 90.0,
    verification_score: 90.0,
    quality_check: "BELUM QC",
    qc_result: "-",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    score: 90.0,
    verification_score: 90.0,
    quality_check: "BELUM QC",
    qc_result: "-",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    score: 90.0,
    verification_score: 90.0,
    quality_check: "BELUM QC",
    qc_result: "-",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    score: 90.0,
    verification_score: 90.0,
    quality_check: "BELUM QC",
    qc_result: "-",
  },
];

export const jsonRowReviewResults = [
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    maturity: 90.0,
    verification_score: 90.0,
    quality_check: "DISETUJUI",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    maturity: 90.0,
    verification_score: 90.0,
    quality_check: "DISETUJUI",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    maturity: 90.0,
    verification_score: 90.0,
    quality_check: "DISETUJUI",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    maturity: 90.0,
    verification_score: 90.0,
    quality_check: "DISETUJUI",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    maturity: 90.0,
    verification_score: 90.0,
    quality_check: "DISETUJUI",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    maturity: 90.0,
    verification_score: 90.0,
    quality_check: "DISETUJUI",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    maturity: 90.0,
    verification_score: 90.0,
    quality_check: "DISETUJUI",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    maturity: 90.0,
    verification_score: 90.0,
    quality_check: "DISETUJUI",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    maturity: 90.0,
    verification_score: 90.0,
    quality_check: "DISETUJUI",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-01",
    maturity: 90.0,
    verification_score: 90.0,
    quality_check: "DISETUJUI",
  },
];

export const jsonRowRejectedInnovation = [
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
    description:
      "Inovasi dan rancang bangun sama dengan Inovasi nomor: 14.04-6073-61553-2022",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
    description:
      "Inovasi dan rancang bangun sama dengan Inovasi nomor: 14.04-6073-61553-2022",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
    description:
      "Inovasi dan rancang bangun sama dengan Inovasi nomor: 14.04-6073-61553-2022",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
    description:
      "Inovasi dan rancang bangun sama dengan Inovasi nomor: 14.04-6073-61553-2022",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
    description:
      "Inovasi dan rancang bangun sama dengan Inovasi nomor: 14.04-6073-61553-2022",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
    description:
      "Inovasi dan rancang bangun sama dengan Inovasi nomor: 14.04-6073-61553-2022",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
    description:
      "Inovasi dan rancang bangun sama dengan Inovasi nomor: 14.04-6073-61553-2022",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
    description:
      "Inovasi dan rancang bangun sama dengan Inovasi nomor: 14.04-6073-61553-2022",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
    description:
      "Inovasi dan rancang bangun sama dengan Inovasi nomor: 14.04-6073-61553-2022",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
    description:
      "Inovasi dan rancang bangun sama dengan Inovasi nomor: 14.04-6073-61553-2022",
  },
];

export const jsonHeaderQualityControl = [
  {
    key: "code",
    title: "Nomor",
  },
  {
    key: "title",
    title: "Judul",
  },
  {
    key: "pemda",
    title: "Pemda",
  },
  {
    key: "date",
    title: "Waktu Penerapan",
  },
  {
    key: "maturity",
    title: "Kematangan",
  },
  {
    key: "verification_score",
    title: "Skor Verifikasi",
  },
  {
    key: "action",
    title: "Aksi",
  },
];

export const jsonRowQualityControl = [
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
  },
  {
    code: "020418072031",
    title: "Lorem ipsum dolor sit amet consectetur.",
    pemda: "Kabupaten Sampang",
    date: "2020-01-10",
    maturity: "90.0",
    verification_score: "90.0",
  },
];

export const jsonRowIndexRanking = [
  {
    region: "Kabupaten Sampang",
    value_index: "90.0",
    ranking: "1",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Kediri",
    value_index: "90.0",
    ranking: "1",
    predicate: "Sangat Inovatif",
    siagas: "TIDAK",
  },
  {
    region: "Kabupaten Sampang",
    value_index: "90.0",
    ranking: "1",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Kediri",
    value_index: "90.0",
    ranking: "1",
    predicate: "Sangat Inovatif",
    siagas: "TIDAK",
  },
  {
    region: "Kabupaten Sampang",
    value_index: "90.0",
    ranking: "1",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Sampang",
    value_index: "90.0",
    ranking: "1",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Sampang",
    value_index: "90.0",
    ranking: "1",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Sampang",
    value_index: "90.0",
    ranking: "1",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Sampang",
    value_index: "90.0",
    ranking: "1",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Sampang",
    value_index: "90.0",
    ranking: "1",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
];

export const jsonRowFinalIndex = [
  {
    region: "Kabupaten Sampang",
    innovation_total: "90.0",
    value_index: "90.0",
    value_index_verification: "90.0",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Sampang",
    innovation_total: "90.0",
    value_index: "90.0",
    value_index_verification: "90.0",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Sampang",
    innovation_total: "90.0",
    value_index: "90.0",
    value_index_verification: "90.0",
    predicate: "Sangat Inovatif",
    siagas: "TIDAK",
  },
  {
    region: "Kabupaten Sampang",
    innovation_total: "90.0",
    value_index: "90.0",
    value_index_verification: "90.0",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Sampang",
    innovation_total: "90.0",
    value_index: "90.0",
    value_index_verification: "90.0",
    predicate: "Sangat Inovatif",
    siagas: "TIDAK",
  },
  {
    region: "Kabupaten Sampang",
    innovation_total: "90.0",
    value_index: "90.0",
    value_index_verification: "90.0",
    predicate: "Sangat Inovatif",
    siagas: "TIDAK",
  },
  {
    region: "Kabupaten Sampang",
    innovation_total: "90.0",
    value_index: "90.0",
    value_index_verification: "90.0",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Sampang",
    innovation_total: "90.0",
    value_index: "90.0",
    value_index_verification: "90.0",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Sampang",
    innovation_total: "90.0",
    value_index: "90.0",
    value_index_verification: "90.0",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
  {
    region: "Kabupaten Sampang",
    innovation_total: "90.0",
    value_index: "90.0",
    value_index_verification: "90.0",
    predicate: "Sangat Inovatif",
    siagas: "YA",
  },
];
