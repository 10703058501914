export const PREVIEW_ACTION_TABLE = "preview_action_table";
export const APPROVE_ACTION_TABLE = "approve_action_table";
export const REJECT_ACTION_TABLE = "reject_action_table";
export const DOWNLOAD_TABLE = "download_action_table";
export const EDIT_ACTION_TABLE = "edit_action_table";
export const PDF_ACTION_TABLE = "pdf_action_table";
export const EXCEL_ACTION_TABLE = "excel_action_table";
export const DOCUMENT_ACTION_TABLE = "document_action_table";
export const INDICATOR_ACTION_TABLE = "indicator_action_table";
export const DELETE_ACTION_TABLE = "delete_action_table";
export const TRANSFER_ACTION_TABLE = "transfer_action_table";
